import React, { Suspense, useEffect, useState } from "react";

// Libraries
import { Col, Container } from "react-bootstrap";
import { Routes, Route, useLocation, useNavigate, Outlet } from "react-router-dom";
import retina from "retinajs";
import { m, AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Functions
import { fadeIn } from "./Functions/GlobalAnimations";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import Buttons from "./Components/Button/Buttons";
import RegisterNewsButton from "./Components/RegisterNewsButton";
import InViewPort from "./Components/InViewPort";

// Pages
import PageLayout from "./Pages/PageLayout";
import HeaderLayout from "./Pages/HeaderLayout";
import FooterLayout from "./Pages/FooterLayout";
import Contact from "./Pages/Contact";
import LoadingAnimation from "./Pages/LoadingAnimation";
import GeneratedPage from "./Pages/GeneratedPage";
import ComingSoonPage from "./Pages/ComingSoonPage";
import ComingSoonV2Page from "./Pages/ComingSoonV2Page";


const MainLayout = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
      controlCommingSoon();
    }, 500);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, [location]);

  const controlCommingSoon = () => {
    // const url = window.location.href;
    // const devUrl = "u1.yazilimdev.com";
    // if (!url.includes(devUrl) && !url.includes("localhost")) {
    //   navigate('/commingSoon');
    // }
  }

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <div style={props.style}>
      <HeaderLayout />
      <Outlet />
      {/* Lazy Load HTML */}
      <InViewPort>
        {/* Footer Start */}
        <FooterLayout />
        {/* Footer End */}
      </InViewPort>
    </div>
  );
};

export const NotFoundLayout = (props) => {
  return (
    <>
      {/* Section Start */}
      <section
        className="cover-background overflow-hidden flex items-center justify-center p-0 bg-dark"
        //style={{ backgroundImage: `url("/assets/img/webp/404-bg.webp")` }}
      >
        <Container>
          <m.div className="row items-stretch full-screen justify-center" {...fadeIn}>
            <Col xl={6} lg={7} md={8} className="col-12 text-center flex items-center justify-center flex-col">
              <h6 className="font-serif text-fastblue font-semibold -tracking-[1px] mb-[10px] uppercase">Ooops!</h6>
              <h1 className="font-serif text-[230px] leading-[230px] font-bold tracking-[-5px] text-light mb-24 lg:text-[170px] lg:leading-[170px] md:text-[130px] md:leading-[130px] md:mb-16 sm:text-[100px] sm:leading-[100px] xs:text-[55px] xs:leading-[55px] xl:text-[200px] xl:leading-[200px]">
                404
              </h1>
              <span className="font-serif font-medium text-light block mb-[20px]">Sayfa bulunamadı!</span>
              <Buttons
                className="font-medium font-serif rounded-none uppercase mb-0"
                to="/"
                themeColor={["#556fff", "#ff798e"]}
                size="lg"
                color="#fff"
                title="Anasayfaya Git"
              />
            </Col>
          </m.div>
        </Container>
      </section>
      {/* Section End */}
    </>
  );
};

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const [logoInfo, setLogoInfo] = useState({});
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });
    setLogoInfo({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
        logoInfo,
        setLogoInfo,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            {/* <RegisterNewsButton /> */}
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<MainLayout style={{ "--base-color": "#bf8c4c" }} />}>
                    <Route index element={<PageLayout pageKey={"homepage"} />} />
                    <Route path="about" element={<PageLayout pageKey={"about"} />} />
                    <Route path="contact" element={<Contact pageKey={"contact"} />} />
                    <Route path="sss" element={<PageLayout pageKey={"sss"} />} />
                    <Route path="page/:pageKey" element={<GeneratedPage />} />
                    {/* <Route path="blog-list" element={<PageLayout pageKey={"blogList"} />} /> */}
                    {/* <Route path="about" element={<LeftAlignmentPage />} /> */}
                    {/* <Route path="contact" element={<LeftAlignmentPage />} /> */}
                    {/* <Route path="blog-list" element={<BlogList />} /> */}
                    {/* <Route path="post/:id" element={<Post />} /> */}
                    <Route path="*" element={<NotFoundLayout />} />
                  </Route>
                  <Route path="commingSoon" element={<ComingSoonV2Page />} />
                  {/* <Route path="commingSoon2" element={<ComingSoonV2Page />} /> */}
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
