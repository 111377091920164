import React, { memo, useEffect, useRef, useState } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { m, AnimatePresence } from "framer-motion";
import * as Yup from "yup";
import { Form, Formik } from "formik";

// Components
import MessageBox from "../Components/MessageBox/MessageBox";
import { Input } from "../Components/Form/Form";
import SocialIcons from "../Components/SocialIcon/SocialIcons";
import { Footer } from "../Components/Footers/Footer";
import { resetForm, sendEmail } from "../Functions/Utilities";

// Data
import ServerClient from "../API/ServerClient";
import Logo from "../Components/Logo";

const FooterItem = (props) => {
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [inputRef, setInputRef] = useState(null);
  const { item } = props;

  let footerItemDiv = null;
  if (item) {
    if (item.type === "description") {
      footerItemDiv = (
        <Col lg={{ span: 3, order: 0 }} sm={{ span: 6, order: 1 }} className="md:mb-[40px] xs:mb-[25px]">
          <span className="font-serif font-medium block text-themecolor mb-[20px] xs:mb-[10px]">{item.title}</span>
          <p className="md:w-9/12 xs:w-full">{item.text}</p>
        </Col>
      );
    } else if (item.type === "links") {
      footerItemDiv = (
        <Col lg={{ span: 2, order: 0 }} md={6} sm={{ span: 6, order: 2 }} className={"footer-menu xl:px-[15px] md:mb-[40px] xs:mb-[25px]"}>
          {item.title && <span className={"mb-[25px] block font-medium font-serif xs:!mb-[10px] capitalize leading-[20px]"}>{item.title}</span>}
          <ul>
            {item.submenu.map((subitem, i) => {
              return (
                (subitem.link || subitem.title) && (
                  <li key={i} className="mb-[7px] last:mb-0">
                    <Link aria-label="footer menu link" to={subitem.link}>
                      {subitem.title}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        </Col>
      );
    } 
    // else if (item.type === "subscribe") {
    //   const setNewsMail = (values, callBack) => {
    //     setFormIsLoading(true);
    //     ServerClient.actions.post(ServerClient.requests.SetNewsMail, values, (response) => {
    //       if (response?.success) {
    //         callBack?.();
    //       }
    //       setFormIsLoading(false);
    //     });
    //   };
    //   window.addEventListener("scroll", () => {
    //     const scrolled = document.documentElement.scrollTop;
    //     if (scrolled === document.body.scrollHeight - window.innerHeight) {
    //       inputRef?.current?.focus?.();
    //     }
    //   });
    //   footerItemDiv = (
    //     <Col xl={{ span: 3 }} lg={{ span: 4, order: 0 }} sm={{ span: 6, order: 3 }} md={5}>
    //       <span className="font-serif font-medium block text-themecolor mb-[20px] xs:mb-[10px]">{item.title}</span>
    //       <p className="mb-[25px] md:mb-[20px]">{item.text}</p>
    //       <Formik
    //         initialValues={{ mail: "" }}
    //         validationSchema={Yup.object().shape({ mail: Yup.string().email("Invalid email.").required("Field is required.") })}
    //         onSubmit={async (values, actions) => {
    //           actions.setSubmitting(true);
    //           setNewsMail(values, () => {
    //             resetForm(actions);
    //           });
    //         }}
    //       >
    //         {({ isSubmitting, status }) => (
    //           <>
    //             <Form className="w-full relative flex rounded-[0.25rem] overflow-hidden">
    //               <Input
    //                 getRef={(r) => setInputRef(r)}
    //                 showErrorMsg={false}
    //                 type="email"
    //                 name="mail"
    //                 labelClass="w-full"
    //                 className="border-[1px] border-solid border-transparent py-[13px] pl-[15px] pr-[15px] w-full"
    //                 placeholder="Mail adresinizi giriniz"
    //               />
    //               <button
    //                 aria-label="subscribe"
    //                 type="submit"
    //                 className={`text-lg tracking-[1px] py-[8px] px-[18px] btn-gradient uppercase${isSubmitting ? " yükleniyor" : ""}`}
    //               >
    //                 <i className="feather-mail text-lg m-0"></i>
    //               </button>
    //             </Form>
    //             <AnimatePresence>
    //               {status && (
    //                 <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mt-[20px] w-full text relative">
    //                   <MessageBox
    //                     className="absolute py-[13px] px-[20px] text-md leading-[20px] rounded-[4px]"
    //                     theme="message-box01"
    //                     variant="success"
    //                     message="Mail adresiniz haber bültenimize başarı ile kaydedildi!"
    //                   />
    //                 </m.div>
    //               )}
    //             </AnimatePresence>
    //           </>
    //         )}
    //       </Formik>
    //     </Col>
    //   );
    // }
  }

  return footerItemDiv;
};

const FooterLayout = (props) => {
  const [applicationModel, setApplicationModel] = useState({});
  const [langName, setLangName] = useState(ServerClient.actions.getActiveLang());

  useEffect(() => {
    ServerClient.actions.getAppModel((appModel) => {
      setApplicationModel(appModel);
    });
  }, []);

  let theme = "light";
  let className = "text-[#828282] bg-light consulting-footer";

  if (!applicationModel?.footer) {
    return null;
  }

  const getActiveLangValue = (item) => {
    let newMenuItem = {};
    for (const [key, value] of Object.entries(item)) {
      if (key === "submenu") {
        let valueArray = value.map((v) => {
          return getActiveLangValue(v);
        });
        newMenuItem[key] = valueArray;
      } else {
        let newValue = value;
        if (item?.[key + "OtherLangs"]?.[langName]) {
          newValue = item[key + "OtherLangs"][langName];
        }
        newMenuItem[key] = newValue;
      }
    }
    return newMenuItem;
  };
  let footerItems = applicationModel.footer.map((footerItem) => getActiveLangValue(footerItem));

  return (
    <Footer theme={theme} className={`${className ? ` ${className}` : ""} footer-style-03`}>
      <div className="py-[6%] lg:py-[8%] md:py-[50px] md:pb-0 xs:py-[50px]">
        <Container>
          <Row className="justify-between md:justify-start">
            {footerItems.map((item, index) => {
              return <FooterItem key={index} item={item} />;
            })}
          </Row>
        </Container>
      </div>
      <div className="py-[35px] border-t border-[#ffffff1a]">
        <Container>
          <Row>
            <Col md={3} className="sm:mb-[20px]">
              <Link aria-label="link" to="/" className="sm:flex sm:justify-center">
                <Logo width={"100px"} color={"#FFFFFF"} bgColor={"transparent"} tunnelColor={"rgb(35, 35, 35)"} hiddenSubTitle={true} />
              </Link>
            </Col>
            <Col md={6} className="flex justify-center sm:mb-[20px]">
              <p className="mb-0 xs:text-center">&copy; {`${new Date().getFullYear()} ${applicationModel.siteInfo.copyright}`}</p>
            </Col>
            <Col md={3}>
              <SocialIcons
                size="xs"
                theme="social-icon-style-01"
                className="felx justify-end md:justify-center"
                iconColor={theme === "dark" ? "light" : "dark"}
                data={applicationModel.socialAccounts}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterLayout.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterLayout);
