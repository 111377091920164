import axios from "axios";
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

export const domain = "https://transformlock.com/";

// localHost
// export const mediaHost = "http://127.0.0.1:8000/";
// export const apiHost = "http://127.0.0.1:8000/api/";

// networkHost
// export const mediaHost = "https://u3.yazilimdev.com";
export const apiHost = "https://u3.yazilimdev.com/api/";

export const languages = {
  tr: "Turkish",
  en: "English",
  ar: "اللغة العربية",
  es: "Español",
  pr: "Português"
}

const requests = {
  GetPageData: "GetPageData",
  GetApplicationModel: "GetApplicationModel",
  SendMail: "SendMail",
  SetNewsMail: "SetNewsMail",
};

const actions = {
  get: function get(request, data, callBack) {
    let config = {
      params: data,
    };
    axios
      .get(apiHost + request, config)
      .then(function (response) {
        if (response?.data?.success) {
          callBack(response?.data);
        }
      })
      .catch(function (axiosError) {
        let response = axiosError?.response?.data;
        if (response) {
          callBack(axiosError?.response?.data);
        } else {
          callBack({ success: false, message: "Sunucu erişim problemi!" });
        }
      });
  },
  post: function post(request, data, callBack) {
    let config = {};
    axios
      .post(apiHost + request, data, config)
      .then(function (response) {
        callBack(response?.data);
      })
      .catch(function (axiosError) {
        callBack(axiosError?.response?.data);
      });
  },
  applicationModel: {},
  applicationPageData: {},
  getAppModel: function post(callBack) {
    if (Object.keys(this.applicationModel).length > 0) {
      callBack(this.applicationModel);
    } else {
      this.get(requests.GetApplicationModel, {}, (response) => {
        if (response.success) {
          let responseData = {};
          for (const [key, value] of Object.entries(response.data)) {
            if (["menu", "footer", "socialAccounts"].includes(key)) {
              responseData[key] = Object.values(value);
            } else {
              responseData[key] = value;
            }
          }
          this.applicationModel = responseData;
          callBack(this.applicationModel);
        }
      });
    }
  },
  getPageData: function post(pageKey, callBack) {
    if (this.applicationPageData?.[pageKey]) {
      callBack(this.applicationPageData[pageKey]);
    } else {
      this.get(requests.GetPageData, { pageKey: pageKey }, (response) => {
        if (response.success) {
          this.applicationPageData[pageKey] = response;
          callBack(response);
        }
      });
    }
  },
  getActiveLang: function () {
    let definedLangs = ["tr", "en"];
    let langName = navigator.language || navigator.userLanguage;
    if (langName.includes("-")) {
      langName = langName.split("-")[0];
    }
    let visitorLocalInfo = localStorage.getItem("visitorInfo");
    if (visitorLocalInfo) {
      let visitorInfo = JSON.parse(visitorLocalInfo);
      langName = visitorInfo.langName || langName;
    }
    if (!definedLangs.includes(langName)) {
      langName = "en";
    }
    return langName;
  },
  setActiveLang: function (langName) {
    localStorage.setItem("visitorInfo", JSON.stringify({ langName: langName }));
  },
};

export default {
  apiHost,
  requests,
  actions,
};
