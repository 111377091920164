import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

// Components
import {
  SectionHero,
  SectionAbout,
  SectionExperiences,
  SectionTabList,
  SectionAccordion,
  SectionTeam,
  SectionCounter,
  SectionParallax,
  SectionLastPosts,
  SectionPageTitle,
  SectionFancyTextBox,
  SectionMainAbout,
  SectionClient,
  SectionIconWithText,
  SectionPageContent,
  SectionVizyon,
  SectionBeforeAfter,
} from "./Sections";

// Data
import InViewPort from "../Components/InViewPort";
import ServerClient from "../API/ServerClient";
import { NotFoundLayout } from "../App";
import LoadingAnimation from "./LoadingAnimation";
import PageMetaData from "./PageMetaData";

// Context
import GlobalContext from "../Context/Context";
import ContactForm from "../Components/ContactForm/ContactForm";

const PageLayout = (props) => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [langName, setLangName] = useState(ServerClient.actions.getActiveLang());
  const { setLogoInfo } = useContext(GlobalContext);

  useEffect(() => {
    setIsLoading(true);
    if (props.pageKey) {
      ServerClient.actions.getPageData(props.pageKey, (response) => {
        if (response?.success) {
          setPageDataActiveLang(response.data);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.pageKey]);

  const setPageDataActiveLang = (responseData) => {
    if (langName !== "tr" && responseData.otherLangs?.[langName]) {
      setPageData(responseData.otherLangs?.[langName]);
    } else {
      setPageData(responseData);
    }
  };

  useEffect(() => {
    let pageLogoInfo = {};
    if (pageData?.logoColor) {
      pageLogoInfo.color = pageData.logoColor;
    }
    if (pageData?.logoBgColor) {
      pageLogoInfo.bgColor = pageData.logoBgColor;
    }
    if (pageData?.logoTunnelColor) {
      pageLogoInfo.tunnelColor = pageData.logoTunnelColor;
    }
    setLogoInfo(pageLogoInfo);
  }, [pageData]);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  const onSaveSection = (sectionData) => {
    let newPageData = pageData;
    let sectionIndex = newPageData.sections.findIndex((s) => s.id === sectionData.id);
    newPageData.sections[sectionIndex] = sectionData;
    setPageData(newPageData);
  };

  if (pageData) {
    let pageSections = pageData.sections || [];
    pageSections = pageSections.sort(function (x, y) {
      if (x.order < y.order) return -1;
      if (x.order > y.order) return 1;
      return 0;
    });

    return (
      <>
        <PageMetaData pageData={pageData} />
        {/* Lazy Load HTML */}
        <InViewPort>
          {pageSections.map((section, index) => {
            let sectionDiv = null;
            let sectionProps = {
              key: index,
              sectionData: section,
              editMode: props.editMode,
              onSave: onSaveSection,
            };
            if (section.type === "hero") {
              sectionDiv = <SectionHero {...sectionProps} />;
            } else if (section.type === "pageTitle") {
              sectionDiv = <SectionPageTitle {...sectionProps} />;
            } else if (section.type === "about") {
              sectionDiv = <SectionAbout {...sectionProps} />;
            } else if (section.type === "vizyon") {
              sectionDiv = <SectionVizyon {...sectionProps} />;
            } else if (section.type === "experiences") {
              sectionDiv = <SectionExperiences {...sectionProps} />;
            } else if (section.type === "tabList") {
              sectionDiv = <SectionTabList {...sectionProps} />;
            } else if (section.type === "accordion") {
              sectionDiv = <SectionAccordion {...sectionProps} />;
            } else if (section.type === "beforeafter") {
              sectionDiv = <SectionBeforeAfter {...sectionProps} />;
            } 
            // else if (section.type === "team") {
            //   sectionDiv = <SectionTeam {...sectionProps} />;
            // } 
            // else if (section.type === "counter") {
            //   sectionDiv = <SectionCounter {...sectionProps} />;
            // } 
            else if (section.type === "parallax") {
              sectionDiv = <SectionParallax {...sectionProps} />;
            } else if (section.type === "lastPosts") {
              sectionDiv = <SectionLastPosts {...sectionProps} />;
            } else if (section.type === "fancyTextBox") {
              sectionDiv = <SectionFancyTextBox {...sectionProps} />;
            } else if (section.type === "mainAbout") {
              sectionDiv = <SectionMainAbout {...sectionProps} />;
            } else if (section.type === "clients") {
              sectionDiv = <SectionClient {...sectionProps} />;
            } else if (section.type === "iconWithText") {
              sectionDiv = <SectionIconWithText {...sectionProps} />;
            }else if (section.type === "contactForm") {
              sectionDiv = <ContactForm contactFormSection={section} />;
            }
            return sectionDiv;
          })}
          {pageData.data && <SectionPageContent sectionData={pageData.data} />}
        </InViewPort>
      </>
    );
  } else {
    return <NotFoundLayout />;
  }
};

PageLayout.propTypes = {
  pageKey: PropTypes.any,
};

export default PageLayout;
