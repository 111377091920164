import React from "react";

function isColorCode(str) {
  if (str) {
    const colorStr = str.replace(/\s/g, "");
    const colorCodeRegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    const rgbRegExp = /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/;
    const rgbaRegExp = /^rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(0(\.\d+)?|1(\.0)?)\)$/;
    if (colorCodeRegExp.test(colorStr) || rgbRegExp.test(colorStr) || rgbaRegExp.test(colorStr)) {
      return str;
    }
  }
  return null;
}

function Logo({ className, width, color, bgColor, tunnelColor, hiddenSubTitle, ...otherProps }) {
  const defStyle = {
    width: "150px",
    color: "#000000",
    bgColor: "transparent",
    tunnelColor: "#FFFFFF",
  };
  const mainStyle = {
    width: width || defStyle.width,
    backgroundColor: isColorCode(bgColor) || defStyle.bgColor,
    color: isColorCode(color) || defStyle.color,
    // fill: isColorCode(color) || defStyle.color,
  };
  const tunnelStyle = {
    fill: isColorCode(tunnelColor) || defStyle.tunnelColor,
  };
  const tunnelLineStyle = {
    fill: "none",
    stroke: isColorCode(color) || defStyle.color,
    strokeWidth: "5px",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: "10px",
  };
  let fillColor = "#59B8BA";
  return (
    <div className={className} style={mainStyle} {...otherProps}>
      <svg
        version="1.1"
        id="katman_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1080"
        style={{enableBackground: "new 0 0 1920 1080"}}
      >
        <g>
          <path
            style={{ fill: fillColor }}
            d="M170.2,660.6c-2.7,1.8-6.9,2.7-12.7,2.7H74.2c-5.7,0-9.9-0.9-12.7-2.7h-4l-1.3,31.1h3.8
		c2.2-7.3,5.6-12.7,10.2-16.1c4.6-3.4,10.7-5.1,18.5-5.1h12.9v98.6c0,6.7-1.1,11.3-3.3,13.8c-2.2,2.5-6.5,4.4-12.8,5.6v3.8h60.6
		v-3.8c-6.1-1.1-10.3-3-12.5-5.7c-2.2-2.7-3.3-7.2-3.3-13.5v-98.8H143c7.8,0,14,1.7,18.5,5.1c4.6,3.4,7.9,8.7,10.2,16.1h3.8
		l-1.3-31.1H170.2z"
          />
          <path
            style={{ fill: fillColor }}
            d="M294.4,759.6c-6.7-12.5-11.8-20.9-15.3-25.2c-3.5-4.3-6.8-6.8-9.8-7.5v-0.4c8.4-2.4,14.9-6.3,19.4-11.6
		c4.5-5.3,6.8-11.7,6.8-19.1c0-10.3-4.3-18.3-13-23.9c-8.7-5.7-21-8.5-37.1-8.5h-61.4v3.8c6.4,1.2,10.7,3,13,5.3
		c2.3,2.3,3.4,6.4,3.4,12.4v87.4c0,10.1-5.5,15.6-16.4,16.5v3.8h61.4v-3.8c-6-0.9-10.3-2.7-12.8-5.4c-2.5-2.8-3.8-7-3.8-12.7v-39.7
		c8.3,0,13.8,0.7,16.5,2.1c2.8,1.4,5.4,3.8,8,7.3c2.5,3.4,6.5,10.4,11.7,20.8c7.6,15.1,13.9,25.6,18.8,31.4H321v-3.8
		c-5.2-0.8-9.3-2.8-12.2-6C305.8,779.4,301,771.7,294.4,759.6z M257.4,717.2c-4.7,4.4-12.2,6.6-22.5,6.6c-1.9,0-4-0.1-6.3-0.2v-52.9
		h8.8c18,0,26.9,8.5,26.9,25.5C264.5,705.8,262.1,712.8,257.4,717.2z"
          />
          <path
            style={{ fill: fillColor }}
            d="M443.5,763.4L404.2,660h-17.6l-37.1,99.4c-3.7,9.9-7.4,17.1-11.1,21.4c-3.7,4.4-8.1,7-13.1,7.9v3.8h41.7v-3.8
		c-8.6-2.3-13-6.3-13-12c0-2.7,0.8-6.2,2.4-10.6l6.4-17.1h46.1l7.5,19.8c1.7,4.5,2.5,7.8,2.5,9.9c0,2.7-1.1,4.7-3.3,6.2
		c-2.2,1.5-5.9,2.7-11.2,3.7v3.8h63.3v-3.8c-5.5-0.4-10-2.5-13.5-6.1C450.6,779,447.1,772.6,443.5,763.4z M366.9,739.7l18.8-52.4
		l19.7,52.4H366.9z"
          />
          <path
            style={{ fill: fillColor }}
            d="M570.7,667.1c6.3,1.4,10.5,3.7,12.8,7c2.2,3.3,3.4,9.2,3.4,17.7v58.1l-75.5-86.5h-38.8v3.8
		c2.6,0.5,4.8,1.5,6.7,2.9c1.9,1.4,5.4,4.7,10.4,9.9v87.6c0,6.8-1.3,11.7-3.9,14.9c-2.6,3.2-6.9,5.3-13,6.2v3.8h44.5v-3.8
		c-7.6-1.2-12.7-3.2-15.2-6c-2.5-2.8-3.8-7.6-3.8-14.5v-78.9L591.3,794h4.2V688.2c0-13.4,6.3-20.4,18.8-21.1v-3.8h-43.5V667.1z"
          />
          <path
            style={{ fill: fillColor }}
            d="M709,758.3c0-7.8-2.3-14.9-6.9-21.5c-3-4.2-6.2-7.7-9.5-10.5c-3.3-2.7-10.6-7.6-22-14.5
		c-11.2-6.9-17.7-11.4-19.8-13.4c-2-2-3.6-4.2-4.7-6.5c-1.1-2.3-1.6-4.7-1.6-7.1c0-4.9,2.1-9,6.2-12.4c4.1-3.4,9.1-5.1,15-5.1
		c6.5,0,12.9,2.6,19,7.7c6.1,5.1,10.5,11.5,13,19h3.8V660h-3.8c-2.4,2.4-4.6,3.6-6.7,3.6c-2.2,0-5.8-0.6-11-1.7
		c-5.2-1.2-9.6-1.8-13.2-1.8c-11.9,0-21.7,3.5-29.2,10.6c-7.6,7.1-11.4,16.1-11.4,27.2c0,5.2,0.9,10.1,2.8,14.7
		c1.9,4.6,4.7,8.8,8.4,12.6c3.7,3.7,11.3,9,22.9,15.9c12.5,7.4,20.2,13.1,23.1,16.9c2.9,3.8,4.3,7.9,4.3,12.2
		c0,5.3-2.2,9.7-6.7,13.1c-4.5,3.4-10.2,5.2-17.1,5.2c-8.9,0-16.4-2.9-22.5-8.7c-6.1-5.8-10.1-13.7-12.1-23.8h-3.8v38.9h3.8
		c1.4-2.8,3.6-4.2,6.6-4.2c1.8,0,5,0.6,9.5,1.7c8.5,2.2,15.9,3.3,22.1,3.3c11.5,0,21.3-3.4,29.4-10.1C705,778.9,709,769.8,709,758.3
		z"
          />
          <path
            style={{ fill: fillColor }}
            d="M771.9,770.8v-39.9h20c6.2,0,10.8,1.2,13.9,3.7c3.1,2.5,5.1,6.6,6.1,12.4h3.8V707H812c-1.7,6.3-4,10.7-7,13
		c-2.9,2.4-7.3,3.6-13.1,3.6h-20v-52.9h22.3c6.1,0,10.9,1.3,14.5,3.9c3.6,2.6,6.5,7,8.8,13h3.8l-2.6-24.2h-91.5v3.8
		c6.6,1.8,10.9,3.8,13,6.1c2.1,2.3,3.2,6.1,3.2,11.3v83.2c0,7.4-1.1,12.5-3.3,15.3c-2.2,2.8-6.5,4.7-12.9,5.7v3.8h60.6v-3.8
		c-6-1-10.1-2.8-12.4-5.4C773.1,780.7,771.9,776.5,771.9,770.8z"
          />
          <path
            style={{ fill: fillColor }}
            d="M953.9,776.7c13.2-12.7,19.8-29,19.8-48.7c0-19.8-6.6-36-19.8-48.8c-13.2-12.8-30.1-19.2-50.7-19.2
		c-20.4,0-37.2,6.4-50.4,19.2c-13.2,12.8-19.8,29.1-19.8,48.8c0,19.8,6.6,36,19.7,48.7c13.1,12.7,30,19.1,50.4,19.1
		C923.8,795.8,940.7,789.4,953.9,776.7z M874.1,772.8c-6.8-10.4-10.1-25-10.1-43.8c0-20.2,3.3-35.5,10-46
		c6.7-10.5,16.4-15.7,29.2-15.7c12.9,0,22.8,5.2,29.5,15.7c6.7,10.5,10.1,25.8,10.1,45.8c0,19-3.5,33.7-10.5,44.1
		c-7,10.3-16.7,15.5-29.1,15.5C890.6,788.5,880.9,783.3,874.1,772.8z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1099.3,759.6c-6.7-12.5-11.8-20.9-15.3-25.2s-6.8-6.8-9.8-7.5v-0.4c8.4-2.4,14.9-6.3,19.4-11.6
		c4.5-5.3,6.8-11.7,6.8-19.1c0-10.3-4.3-18.3-13-23.9c-8.7-5.7-21-8.5-37.1-8.5h-61.4v3.8c6.4,1.2,10.7,3,13,5.3
		c2.3,2.3,3.4,6.4,3.4,12.4v87.4c0,10.1-5.5,15.6-16.4,16.5v3.8h61.4v-3.8c-6-0.9-10.3-2.7-12.8-5.4c-2.5-2.8-3.8-7-3.8-12.7v-39.7
		c8.3,0,13.8,0.7,16.5,2.1c2.8,1.4,5.4,3.8,8,7.3c2.5,3.4,6.5,10.4,11.7,20.8c7.6,15.1,13.9,25.6,18.8,31.4h37.2v-3.8
		c-5.2-0.8-9.3-2.8-12.2-6C1110.6,779.4,1105.8,771.7,1099.3,759.6z M1062.3,717.2c-4.7,4.4-12.2,6.6-22.5,6.6c-1.9,0-4-0.1-6.2-0.2
		v-52.9h8.8c18,0,26.9,8.5,26.9,25.5C1069.3,705.8,1067,712.8,1062.3,717.2z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1303.8,768.6l-11.7-80.6c-0.3-2.1-0.4-3.9-0.4-5.6c0-4.5,1.2-7.9,3.6-10.3c2.4-2.4,6.2-4.1,11.4-5v-3.8H1263
		l-34.1,85.1l-33.7-85.1h-46.7v3.8c7,0.2,13.3,3.2,18.8,8.8l-12.7,84.3c-1.6,10.1-4.1,17.3-7.5,21.6c-3.4,4.3-8.6,6.6-15.4,6.8v3.8
		h46.8v-3.8c-10.6-0.8-15.8-6.8-15.8-18.1c0-2.4,0.2-5.2,0.6-8.2l11.2-75.9l41.9,106h4.6l42.5-106l10.6,73.7c0.5,3.8,0.8,7,0.8,9.7
		c0,5.4-1.2,9.5-3.4,12.4c-2.3,2.9-6.1,5-11.4,6.4v3.8h63.8v-3.8c-6-1.1-10.6-3.3-13.7-6.5C1306.7,779.1,1304.7,774.5,1303.8,768.6z
		"
          />
          <path
            style={{ fill: fillColor }}
            d="M1437.4,758.9h-3.8c-5,10.2-9.7,17.1-14.3,20.7c-4.6,3.6-11,5.4-19.2,5.4c-7.9,0-13.3-1-16.1-2.9
		c-2.8-1.9-4.3-5.6-4.3-11v-87c0-5.2,1-9,3-11.2c2-2.2,6.5-4.2,13.4-5.9v-3.8h-61v3.8c6.6,1.6,10.9,3.5,13,5.9
		c2.1,2.3,3.2,6.4,3.2,12.2v82.6c0,7.5-1,12.4-2.9,14.8s-6.4,4.4-13.2,6.1v3.8h98.5L1437.4,758.9z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1519.2,660c-20.4,0-37.2,6.4-50.4,19.2c-13.2,12.8-19.8,29.1-19.8,48.8c0,19.8,6.6,36,19.7,48.7
		c13.1,12.7,30,19.1,50.4,19.1c20.5,0,37.4-6.4,50.7-19.1c13.2-12.7,19.8-29,19.8-48.7c0-19.8-6.6-36-19.8-48.8
		C1556.6,666.4,1539.7,660,1519.2,660z M1548.3,772.9c-7,10.3-16.7,15.5-29.1,15.5c-12.6,0-22.3-5.2-29.1-15.6
		c-6.8-10.4-10.1-25-10.1-43.8c0-20.2,3.3-35.5,10-46c6.7-10.5,16.4-15.7,29.2-15.7c12.9,0,22.8,5.2,29.5,15.7
		c6.7,10.5,10.1,25.8,10.1,45.8C1558.8,747.9,1555.3,762.6,1548.3,772.9z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1707.5,778.3c-6.5,3.2-13.7,4.8-21.6,4.8c-14.5,0-25.9-5.2-34.2-15.6c-8.3-10.4-12.4-25.2-12.4-44.2
		c0-17.1,3.5-30.7,10.6-40.8c7.1-10.1,16.6-15.2,28.6-15.2c11,0,20.3,3.6,28,10.8c7.7,7.2,13,17.3,16,30.3h3.6v-47.3h-3.6
		c-2.2,4.5-4.8,6.7-8,6.7c-1.6,0-4.6-0.8-8.9-2.4c-10.2-3.6-19.4-5.4-27.5-5.4c-19.8,0-36.4,6.7-49.7,20c-13.3,13.3-20,29.9-20,49.7
		c0,19.5,6.2,35.4,18.5,47.7c12.4,12.3,28.4,18.4,48.2,18.4c22.1,0,39.5-8.8,52.3-26.3v-6.8C1720.7,769.9,1714,775.1,1707.5,778.3z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1869,785.5c-2.4-1.7-5.8-5.3-10.1-10.6l-51.7-63.6l24-22.7c8.3-8,15.4-13.5,21.3-16.7
		c5.9-3.2,11.8-4.8,17.8-4.8v-3.8h-53.7v3.8c8,1.9,12,4.9,12,9.1c0,2.4-1.3,4.9-4,7.4l-39.8,38.3v-38.3c0-4.6,1.1-8.1,3.4-10.6
		c2.2-2.5,6.5-4.4,12.8-5.9v-3.8h-61v3.8c6.7,1.6,11.1,3.5,13.2,5.8c2.1,2.3,3.2,6.4,3.2,12.3v84.7c0,6-1,10.3-3.1,12.7
		c-2,2.4-6.5,4.5-13.3,6v3.8h61v-3.8c-7.2-2.2-11.7-4.4-13.5-6.7c-1.8-2.3-2.7-6.6-2.7-12.9v-42.1l40.4,50.1c1.8,2.2,2.7,4,2.7,5.5
		c0,2.7-3,4.8-8.9,6.1v3.8h59v-3.8C1874.4,788.3,1871.4,787.3,1869,785.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M511.5,850.5c2.3,0.6,3.8,1.2,4.5,2c0.7,0.8,1.1,2.2,1.1,4.3v12.3h-21.6v-12.6c0-1.8,0.4-3.1,1.1-4
		c0.8-0.8,2.2-1.5,4.4-2v-1.3h-21.1v1.3c2.3,0.6,3.9,1.2,4.6,2c0.7,0.8,1.1,2.2,1.1,4.3v28.8c0,2-0.2,3.4-0.5,4.1s-0.9,1.3-1.7,1.8
		c-0.8,0.5-2,0.9-3.6,1.3v1.3H501v-1.3c-2.3-0.6-3.8-1.3-4.5-2c-0.7-0.8-1-2.2-1-4.3v-14.2h21.6v13.3c0,2.6-0.4,4.4-1.2,5.2
		c-0.8,0.9-2.3,1.5-4.5,2v1.3h21.3v-1.3c-2.4-0.6-3.9-1.3-4.6-2.1c-0.7-0.8-1.1-2.2-1.1-4.2v-28.8c0-2.7,0.4-4.4,1.1-5.2
		s2.3-1.5,4.7-2.1v-1.3h-21.3V850.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M601.1,884l-13.7-36h-6.1l-12.9,34.6c-1.3,3.5-2.6,5.9-3.9,7.5c-1.3,1.5-2.8,2.4-4.6,2.7v1.3h14.5v-1.3
		c-3-0.8-4.5-2.2-4.5-4.2c0-0.9,0.3-2.2,0.9-3.7l2.2-6H589l2.6,6.9c0.6,1.6,0.9,2.7,0.9,3.5c0,0.9-0.4,1.6-1.1,2.2
		c-0.8,0.5-2.1,0.9-3.9,1.3v1.3h22v-1.3c-1.9-0.2-3.5-0.9-4.7-2.1C603.6,889.5,602.4,887.2,601.1,884z M574.4,875.8l6.6-18.2
		l6.9,18.2H574.4z"
          />
          <path
            style={{ fill: fillColor }}
            d="M636.8,850.5c2.3,0.6,3.9,1.2,4.6,2c0.7,0.8,1.1,2.2,1.1,4.3v28.8c0,2.1-0.2,3.4-0.5,4.2
		c-0.3,0.7-0.9,1.3-1.7,1.8s-2,0.9-3.6,1.3v1.3H658v-1.3c-2.3-0.6-3.8-1.3-4.6-2.1c-0.7-0.8-1.1-2.2-1.1-4.2v-28.8
		c0-2.6,0.4-4.4,1.2-5.2c0.8-0.8,2.3-1.5,4.5-2v-1.3h-21.3V850.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M725.1,882.7c-2.3-4.4-4.1-7.3-5.3-8.8c-1.2-1.5-2.4-2.4-3.4-2.6v-0.1c2.9-0.9,5.2-2.2,6.7-4.1
		c1.6-1.9,2.4-4.1,2.4-6.6c0-3.6-1.5-6.4-4.5-8.3c-3-2-7.3-3-12.9-3h-21.4v1.3c2.2,0.4,3.7,1,4.5,1.8c0.8,0.8,1.2,2.2,1.2,4.3v30.5
		c0,3.5-1.9,5.4-5.7,5.7v1.3H708v-1.3c-2.1-0.3-3.6-0.9-4.5-1.9c-0.9-1-1.3-2.4-1.3-4.4v-13.8c2.9,0,4.8,0.2,5.8,0.7
		c1,0.5,1.9,1.3,2.8,2.5c0.9,1.2,2.3,3.6,4.1,7.2c2.7,5.3,4.8,8.9,6.5,10.9h13v-1.3c-1.8-0.3-3.2-1-4.3-2.1
		C729.1,889.6,727.4,887,725.1,882.7z M712.2,868c-1.6,1.5-4.3,2.3-7.8,2.3c-0.7,0-1.4,0-2.2-0.1v-18.4h3.1c6.3,0,9.4,3,9.4,8.9
		C714.7,864,713.9,866.4,712.2,868z"
          />
          <path
            style={{ fill: fillColor }}
            d="M829.8,848.3c-0.9,0.6-2.4,0.9-4.4,0.9h-29c-2,0-3.5-0.3-4.4-0.9h-1.4l-0.5,10.9h1.3c0.8-2.6,2-4.4,3.5-5.6
		c1.6-1.2,3.7-1.8,6.5-1.8h4.5v34.4c0,2.3-0.4,3.9-1.2,4.8c-0.8,0.9-2.3,1.5-4.5,2v1.3h21.1v-1.3c-2.1-0.4-3.6-1-4.4-2
		c-0.8-0.9-1.2-2.5-1.2-4.7v-34.4h4.5c2.7,0,4.9,0.6,6.5,1.8c1.6,1.2,2.8,3,3.5,5.6h1.3l-0.5-10.9H829.8z"
          />
          <path
            style={{ fill: fillColor }}
            d="M896.1,882.7c-2.3-4.4-4.1-7.3-5.3-8.8c-1.2-1.5-2.4-2.4-3.4-2.6v-0.1c2.9-0.9,5.2-2.2,6.7-4.1
		c1.6-1.9,2.4-4.1,2.4-6.6c0-3.6-1.5-6.4-4.5-8.3c-3-2-7.3-3-12.9-3h-21.4v1.3c2.2,0.4,3.7,1,4.5,1.8c0.8,0.8,1.2,2.2,1.2,4.3v30.5
		c0,3.5-1.9,5.4-5.7,5.7v1.3H879v-1.3c-2.1-0.3-3.6-0.9-4.5-1.9c-0.9-1-1.3-2.4-1.3-4.4v-13.8c2.9,0,4.8,0.2,5.8,0.7
		c1,0.5,1.9,1.3,2.8,2.5c0.9,1.2,2.3,3.6,4.1,7.2c2.7,5.3,4.8,8.9,6.5,10.9h13v-1.3c-1.8-0.3-3.2-1-4.3-2.1
		C900.1,889.6,898.4,887,896.1,882.7z M883.3,868c-1.6,1.5-4.3,2.3-7.8,2.3c-0.7,0-1.4,0-2.2-0.1v-18.4h3.1c6.3,0,9.4,3,9.4,8.9
		C885.7,864,884.9,866.4,883.3,868z"
          />
          <path
            style={{ fill: fillColor }}
            d="M971.1,884l-13.7-36h-6.1l-12.9,34.6c-1.3,3.5-2.6,5.9-3.9,7.5c-1.3,1.5-2.8,2.4-4.6,2.7v1.3h14.5v-1.3
		c-3-0.8-4.5-2.2-4.5-4.2c0-0.9,0.3-2.2,0.9-3.7l2.2-6h16.1l2.6,6.9c0.6,1.6,0.9,2.7,0.9,3.5c0,0.9-0.4,1.6-1.1,2.2
		c-0.8,0.5-2.1,0.9-3.9,1.3v1.3h22v-1.3c-1.9-0.2-3.5-0.9-4.7-2.1C973.6,889.5,972.4,887.2,971.1,884z M944.5,875.8l6.6-18.2
		l6.8,18.2H944.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1038.5,850.5c2.2,0.5,3.7,1.3,4.5,2.4c0.8,1.1,1.2,3.2,1.2,6.2v20.2l-26.3-30.2h-13.5v1.3
		c0.9,0.2,1.7,0.5,2.3,1c0.7,0.5,1.9,1.7,3.6,3.5v30.5c0,2.4-0.5,4.1-1.4,5.2c-0.9,1.1-2.4,1.8-4.5,2.2v1.3h15.5v-1.3
		c-2.7-0.4-4.4-1.1-5.3-2.1c-0.9-1-1.3-2.6-1.3-5v-27.5l32.4,36.5h1.5v-36.8c0-4.7,2.2-7.1,6.5-7.4v-1.3h-15.2V850.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1104.1,871.1c-1.2-1-3.7-2.6-7.7-5.1c-3.9-2.4-6.2-4-6.9-4.7c-0.7-0.7-1.3-1.5-1.6-2.3
		c-0.4-0.8-0.6-1.6-0.6-2.5c0-1.7,0.7-3.1,2.2-4.3s3.2-1.8,5.2-1.8c2.3,0,4.5,0.9,6.6,2.7c2.1,1.8,3.6,4,4.5,6.6h1.3V848h-1.3
		c-0.8,0.8-1.6,1.2-2.3,1.2c-0.7,0-2-0.2-3.8-0.6c-1.8-0.4-3.3-0.6-4.6-0.6c-4.1,0-7.5,1.2-10.2,3.7c-2.6,2.5-4,5.6-4,9.5
		c0,1.8,0.3,3.5,1,5.1c0.7,1.6,1.6,3.1,2.9,4.4c1.3,1.3,4,3.2,8,5.5c4.4,2.6,7,4.6,8,5.9c1,1.3,1.5,2.8,1.5,4.3
		c0,1.9-0.8,3.4-2.3,4.6c-1.6,1.2-3.5,1.8-5.9,1.8c-3.1,0-5.7-1-7.8-3c-2.1-2-3.5-4.8-4.2-8.3h-1.3V895h1.3c0.5-1,1.2-1.5,2.3-1.5
		c0.6,0,1.7,0.2,3.3,0.6c3,0.8,5.5,1.2,7.7,1.2c4,0,7.4-1.2,10.2-3.5c2.8-2.4,4.2-5.5,4.2-9.5c0-2.7-0.8-5.2-2.4-7.5
		C1106.3,873.3,1105.2,872.1,1104.1,871.1z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1172.7,852.5c-2.7-2.2-6.5-3.3-11.2-3.3h-23.1v1.3c2.4,0.5,3.9,1.1,4.6,1.9c0.7,0.8,1.1,2.2,1.1,4.2V886
		c0,2.3-0.4,3.9-1.1,4.8c-0.8,0.9-2.3,1.6-4.6,2v1.3h21.2v-1.3c-2.1-0.4-3.6-1-4.4-1.9c-0.8-0.9-1.2-2.4-1.2-4.4v-12.8
		c2,0.2,3.9,0.4,5.7,0.4c5.3,0,9.5-1.2,12.6-3.6c3-2.4,4.6-5.5,4.6-9.3C1176.8,857.6,1175.4,854.7,1172.7,852.5z M1163.7,868.9
		c-1.6,1.8-3.7,2.6-6.5,2.6c-1.1,0-2.2-0.2-3.3-0.5v-19.3h3.8c2.5,0,4.5,0.9,6,2.7s2.3,4.2,2.3,7.1
		C1166.1,864.7,1165.3,867.2,1163.7,868.9z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1232.8,889.7c-1.6,1.3-3.8,1.9-6.7,1.9c-2.8,0-4.6-0.3-5.6-1c-1-0.7-1.5-2-1.5-3.8v-30.3
		c0-1.8,0.4-3.1,1.1-3.9c0.7-0.8,2.3-1.5,4.7-2.1v-1.3h-21.2v1.3c2.3,0.6,3.8,1.2,4.5,2c0.7,0.8,1.1,2.2,1.1,4.3v28.8
		c0,2.6-0.3,4.3-1,5.1c-0.7,0.8-2.2,1.5-4.6,2.1v1.3h34.3l1.3-11.7h-1.3C1236.1,886.1,1234.4,888.5,1232.8,889.7z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1305.2,884l-13.7-36h-6.1l-12.9,34.6c-1.3,3.5-2.6,5.9-3.9,7.5s-2.8,2.4-4.6,2.7v1.3h14.5v-1.3
		c-3-0.8-4.5-2.2-4.5-4.2c0-0.9,0.3-2.2,0.9-3.7l2.2-6h16.1l2.6,6.9c0.6,1.6,0.9,2.7,0.9,3.5c0,0.9-0.4,1.6-1.1,2.2
		c-0.8,0.5-2.1,0.9-3.9,1.3v1.3h22v-1.3c-1.9-0.2-3.5-0.9-4.7-2.1C1307.7,889.5,1306.4,887.2,1305.2,884z M1278.5,875.8l6.6-18.2
		l6.9,18.2H1278.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1372.6,850.5c2.2,0.5,3.7,1.3,4.5,2.4c0.8,1.1,1.2,3.2,1.2,6.2v20.2l-26.3-30.2h-13.5v1.3
		c0.9,0.2,1.7,0.5,2.3,1c0.7,0.5,1.9,1.7,3.6,3.5v30.5c0,2.4-0.5,4.1-1.4,5.2c-0.9,1.1-2.4,1.8-4.5,2.2v1.3h15.5v-1.3
		c-2.7-0.4-4.4-1.1-5.3-2.1c-0.9-1-1.3-2.6-1.3-5v-27.5l32.4,36.5h1.5v-36.8c0-4.7,2.2-7.1,6.5-7.4v-1.3h-15.2V850.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1452.4,848.3c-0.9,0.6-2.4,0.9-4.4,0.9h-29c-2,0-3.5-0.3-4.4-0.9h-1.4l-0.5,10.9h1.3c0.8-2.6,2-4.4,3.5-5.6
		c1.6-1.2,3.7-1.8,6.5-1.8h4.5v34.4c0,2.3-0.4,3.9-1.2,4.8c-0.8,0.9-2.3,1.5-4.5,2v1.3h21.1v-1.3c-2.1-0.4-3.6-1-4.4-2
		c-0.8-0.9-1.2-2.5-1.2-4.7v-34.4h4.5c2.7,0,4.9,0.6,6.5,1.8c1.6,1.2,2.8,3,3.5,5.6h1.3l-0.5-10.9H1452.4z"
          />
          <path
            style={{ fill: fillColor }}
            d="M935.3,290.7c-10.8,16.1-17.9,33.7-19.1,53.4c-1.1,17.9,2.2,34.6,11.7,50c7.3,11.9,16.4,22.1,26.8,31.2
		c1.5,1.4,2.4,2.6,2.1,4.9c-0.6,4.7-0.5,9.5,0.2,14.2c1.8,11.2,7.5,20.4,14.4,29.1c7.4,9.3,15.9,17.5,23,27.1c1,1.4,1.5,0.4,2-0.5
		c8.7-13.1,15-26.9,15.2-43c0.1-10.1-1.2-19.7-5.2-29c-5.7-12.9-15.1-22.9-25-32.5c-8.6-8.3-17.8-16-25.6-25.1
		c-9.7-11.4-15.3-24.5-16-39.5c-0.3-3.3-0.4-6.6,0.6-9.8c0-0.4,0-0.8,0-1.1c2.1-13.3,8.5-24.4,17.7-34c2.7-2.8,5.2-5.8,8.7-7.7
		c0.2-0.3,0.4-0.5,0.7-0.8c0.4-0.3,0.9-0.6,1.3-0.9c8.9-7.5,19.4-11.9,30.3-15.6c15.9-5.4,32.4-8.5,49-10.8
		c5.9-0.8,11.9-1.6,17.8-2.5c1.5-0.2,2.8,0.1,3.9,1.4c-2.5,1.5-5.3,2.1-8.1,2.8c-4.7,1.3-9.4,3-14.3,3.6c-10.7,2.6-21.5,5-32.1,7.9
		c-15.9,4.3-31.6,9.4-44.6,20.1c-5.4,4.4-9.9,9.7-14,15.4c-1.5,3.1-2.8,6.3-4.5,9.2c-0.7,1.1-0.6,2.2-1.1,3.2
		c-1.8,6.9-2.3,12.8-1.4,15.7c4.9-12.8,13.8-22.4,25-29.9c10.6-7.2,22.5-11.4,34.7-15c17.6-5.1,35.5-9.1,52.8-15
		c12.4-4.3,24.6-9.1,36.2-15.4c1.4-0.7,2.7-1.4,4.4-1.6c0,4.5,0,8.9,0,13.2c0.2,12.7,2.8,24.7,8.9,35.9c2.3,4.3,4.9,8.4,8.8,11.5
		c0.8,0.7,1.7,1.7,2.9,1.1c1.1-0.5,0.6-1.8,0.7-2.7c0-0.3,0-0.6,0-0.9c0.1-26.6,0.1-53.1,0.2-79.7c0-2.5-1-3.4-3.4-3.4
		c-16.4,0.1-32.8,0-49.3,0c-14.4,0-28.8-0.2-43,2.9C989.4,236.9,957.8,257.1,935.3,290.7z M942,376.5c1.6,2.3,3.2,4.6,4.9,6.9
		c0.4,0.6,1.2,0.9,1,1.7l0,0c4.2,4.4,8.4,9,12.7,13.3c6.9,7,14.1,13.6,20.8,20.8c0.3,0.4,0.6,0.8,1,1.2c4.1,4,7.1,8.7,9.9,13.6h0
		c0,0,0,0,0,0.1c0.3,0.5,0.5,1,0.8,1.5c0.2,0.3,0.3,0.6,0.3,0.9c2,4.6,5.6,15,4.2,27.5c0,0-3.9-18.1-10.5-27.2
		c-0.8-0.9-1.5-1.8-2.3-2.7c0,0,0,0,0,0h0c-2.9-3.3-5.7-6.6-8.6-10c-10-9.7-20.5-18.9-29.2-29.8c-11.8-13.5-17.5-29.2-16.8-47.1
		C930.7,355.5,935.5,367.3,942,376.5z M998.6,249.9c23.1-12.2,47.9-16.4,73.7-15.8c1.2,0,2.4,0.1,3.6,0.1c6-0.2,12.1,0,18,1.5
		c-6.4,0.9-12.7,2.5-19.2,2.6c-25.1,3-50.2,6.2-74.6,13.1c-2.8,1-5.5,2.1-8.3,3.1c-0.1-0.2-0.2-0.5-0.4-0.8
		C993.8,252.6,996.2,251.2,998.6,249.9z"
          />
          <path
            style={{ fill: fillColor }}
            d="M920.5,280.6c3,0.7,5.1,1.1,7-2.2c5.2-9.1,12.5-16.4,20.6-23.2c-7.6-3.3-15.4-5.2-23.2-6.8
		c-6.6-1.3-13.2-2.5-19.9-3.8c-4.8,0.2-9.4-1-14.1-1.4c-3.8-0.3-7.5-1.3-11.3-1.5c-1.5-0.1-2.5-0.8-3.7-1.7c9.4-2.5,19-1.8,28.5-0.8
		c1.1,0,2.2-0.1,3.3,0c14.7,1.3,29,4.4,42.8,9.4c1.3,0.5,2.5,1.8,4,0.8c4.2-3,8.4-6.2,12.6-9.3c-3.4-2.6-7.4-3.3-11-4.6
		c-17.2-6.3-35.1-9.2-53.2-9.6c-29.8-0.6-59.6-0.3-89.4-0.4c-2.9,0-3.6,1.2-3.6,3.8c0.1,21.6,0.1,43.2,0.1,64.8c0,5.2,0,10.3,0,15.5
		c0,0.9-0.3,2.2,1.1,2.5c1.1,0.3,1.9-0.1,2.7-1c1.2-1.3,2.5-2.4,3.5-3.7c8.9-11.9,13.2-25.5,14-40.2c0.3-5.7,0.2-11.4,0.2-17
		c0.1-0.2,0.2-0.4,0.2-0.6c0.3,0.2,0.6,0.3,0.8,0.5c7.8,4,15.7,7.9,23.9,11.2C877.2,269.6,898.7,275.5,920.5,280.6z"
          />
          <path
            style={{ fill: fillColor }}
            d="M992.3,594.5c1.6,0.2,2.3-0.4,2.7-2c0.8-3.1,1.8-6.2,2.7-9.3c4.2-14.7,5.2-29.4,0.9-44.3
		c-3.8-13.4-11.7-24.3-20.6-34.7c-7.5-8.8-15.8-16.9-22.3-26.4c-9.1-13.1-14.9-27.3-13.8-43.7c0-0.7,0.1-1.5-0.5-2
		c-4.3-3.3-7.8-7.6-11.7-11.6c-8,12.3-12.4,25.4-13.5,39.8c-1.6,21.7,4.2,41,17.4,58.3c9.1,11.9,20.1,21.9,30.5,32.6
		c11.3,11.6,19.7,24.6,22.2,40.9c0.3,1.9,0.9,2.8,2.9,2.5C990.3,594.3,991.3,594.3,992.3,594.5z M987.7,569.5c0,0-5.2-21.1-15-34.1
		c-2-2.1-4-4.2-6.1-6.3c0,0,0,0,0,0c-8.3-6.5-16-16.3-20.7-23c-1.8-2.4-3.4-4.9-4.9-7.4c0,0,0,0,0,0l0,0c-7-12-10.2-25.4-8.8-40.4
		c-0.2,3.3,2.6,13.9,4.3,18.1c3.1,7.2,7.4,13.6,11.5,20.2c6.8,9.3,15.1,17.4,22.6,26.1c2.7,2.6,5,5.4,7.1,8.5
		C993.5,552.7,987.7,569.5,987.7,569.5z"
          />
          <path
            style={{ fill: fillColor }}
            d="M1001.5,365.6c-0.5-3.1-3-9.3-5.1-14.1c0-0.1-0.1-0.1-0.1-0.2c-1.4-3.2-2.6-5.7-2.9-6.3
		c-2.4-4.8-5.1-9.5-8.2-13.9c-3.3-4.3-6.7-8.6-10-12.9c-1.3-1.7-2.5-2.1-4.1,0c-4.8,6.1-8.7,12.7-11.2,20
		c-2.1,6.2-1.4,11.9,2.4,17.3c2.2,3.1,4.6,6.1,7.3,8.9c8.7,9.2,18.6,17.2,27.4,26.3c5.3,5.4,5.2,5.4,8.5-1.6
		c7.5-15.9,9.4-32.5,5.8-49.7c-2.8-13.3-9.7-24.3-18.7-34.2c-0.7-0.7-1.3-1.9-2.7-0.9c-3.2,2.2-6.4,4.3-9.7,6.2
		c-1.1,0.7-1.1,1.3-0.4,2.1c4.9,5.3,9.2,10.9,12.6,17.3c1.2,1.9,2.2,3.8,3,5.8v0C1000.8,342.7,1002.3,371.1,1001.5,365.6z"
          />
        </g>
      </svg>
      {/* <svg version="1.1" id="katman_1" x="0px" y="0px" viewBox={`0 0 689 ${hiddenSubTitle ? 240 : 308}`}>
        <g id="logo">
          <g>
            <g>
              <path
                d="M470,121.9c0,48.2-34.5,84.1-82.4,84.1c-47.9,0-82.4-35.9-82.4-84.1c0-47.9,34.5-83.8,82.4-83.8
          C435.5,38,470,73.9,470,121.9 M430.1,121.9c0-22.9-14.4-45.6-42.6-45.6c-28.3,0-42.6,22.7-42.6,45.6c0,22.9,14.4,45.9,42.6,45.9
          C415.8,167.7,430.1,144.8,430.1,121.9"
              />
              <path
                d="M601,152.7l33.1,21.8c-14.6,19.5-37.7,31.5-65.5,31.5c-47.9,0-82.4-35.9-82.4-84.1c0-47.9,34.5-83.8,82.4-83.8
          c27.8,0,50.9,12,65.5,31.3L601,91.1c-6.9-8.8-17.8-14.8-32.4-14.8c-28.3,0-42.6,22.7-42.6,45.6c0,22.9,14.4,45.9,42.6,45.9
          C583.2,167.7,594.1,161.7,601,152.7"
              />
              <g>
                <path
                  d="M264.5,136.8c15.5-8.8,25-23.4,24.9-42.9c-0.1-32.4-21.2-56-62.4-55.9l-62.3,0.1l0.4,160.7v1.2H258l41.1-0.1
            L264.5,136.8z"
                />
                <polygon style={tunnelStyle} points="218.9,64.6 189.4,202.1 248.3,202.1 				" />
                <line style={tunnelLineStyle} x1="218.9" y1="198.7" x2="218.9" y2="163.8" />
                <line style={tunnelLineStyle} x1="218.9" y1="139.4" x2="218.9" y2="114.7" />
              </g>
            </g>
            <g>
              <path
                d="M79.6,185.9h24.6c8,0,13.3-0.1,13.3-8.9c0-1.7-0.1-2.9-0.2-4.1h8.9c0.3,1.4,0.6,3.2,0.6,5.4c0,12.2-6.5,17.6-18.9,17.6
          H79.6v8.8h-9.7V196H58.1v-10.1h11.8v-13h9.7V185.9z"
              />
              <path
                d="M91,111h35.2v10.2H93.9c-7.9,0-15.8,3.3-15.8,13.9c0,10.6,9.2,15,15.8,15h32.3v10.1H48.5v-10.1h29.3
          c-5-3.3-9.2-8.9-9.2-17.4C68.6,120,79.1,111,91,111z"
              />
              <path
                d="M102.4,44.5v44.7c9.2-1.6,15.5-8.4,15.5-19.4c0-6.1-2.3-11.6-6.3-14.8l5.3-8.3c6.8,5,10.6,13.2,10.6,23.1
          c0,18.6-13.3,30.2-29.5,30.2c-16.2,0-29.5-12-29.5-28.5C68.6,56.1,81.4,41.8,102.4,44.5z M93.8,54.7c-9.5,1.3-15.6,7.2-15.6,16.8
          c0,9.7,6.3,16.2,15.6,17.7V54.7z"
              />
            </g>
          </g>
          {!hiddenSubTitle && (
            <g>
              <path
                d="M86.4,262.7l2.1,1.4c-3,3.8-7.7,6.3-13,6.3c-9.5,0-16.8-7.7-16.8-17.2c0-9.5,7.3-17.2,16.8-17.2c5.3,0,10,2.4,13,6.3
            l-2.1,1.4c-2.6-3.1-6.4-5.1-10.9-5.1c-8.1,0-14.2,6.6-14.2,14.6c0,8.1,6.1,14.6,14.2,14.6C80,267.8,83.8,265.8,86.4,262.7z"
              />
              <path
                d="M117,257.7c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6C111.5,245.1,117,250.8,117,257.7z
             M114.5,257.7c0-5.5-4.4-10.1-10-10.1s-10,4.6-10,10.1s4.4,10.1,10,10.1S114.5,263.3,114.5,257.7z"
              />
              <path
                d="M142.6,255.5v14.2H140v-14.2c0-3.3-1.6-7.9-7.1-7.9s-8,4.6-8,7.9v14.2h-2.5v-24h2.5v3.8c1.6-2.5,4.2-4.4,8-4.4
            C139.9,245.1,142.6,250.9,142.6,255.5z"
              />
              <path
                d="M164.7,263.1c0,3.6-2.6,7.2-8.4,7.2c-4.6,0-7.7-2.6-8.6-5.5l2.4-0.8c0.6,1.9,2.9,3.7,6.2,3.7c3.6,0,5.9-1.8,5.9-4.6
            c0-2.6-1.8-3.4-5.7-4.2c-3.6-0.7-7.9-2-7.9-6.8c0-3.9,3.2-7,7.5-7c3.7,0,6.5,2,7.5,5.4l-2.5,0.7c-0.7-2.2-2.5-3.5-5-3.5
            c-2.9,0-5,1.9-5,4.5c0,2.7,2.3,3.6,6,4.4C160.5,257.2,164.7,258.3,164.7,263.1z"
              />
              <path
                d="M180.5,267.4v2.5c-3.9,0.4-8.2,0-8.2-7.3v-14.3h-4.2v-2.6h4.2v-5h2.5v5h5.6v2.6h-5.6v14.3
            C174.8,266.1,175.8,267.8,180.5,267.4z"
              />
              <path d="M197.7,245.1v2.9c-5.3,0-8.5,3.4-8.5,9.4v12.2h-2.6v-24h2.6v5.2C190.9,247.6,193.8,245.1,197.7,245.1z" />
              <path
                d="M222.9,245.7v24h-2.6V266c-1.5,2.5-4.1,4.4-7.9,4.4c-7,0-9.7-5.7-9.7-10.4v-14.2h2.6v14.2c0,3.3,1.6,7.9,7.1,7.9
            s7.9-4.6,7.9-7.9v-14.2H222.9z"
              />
              <path
                d="M249,263.5l2.3,1.2c-2.2,3.4-6.1,5.7-10.4,5.7c-7,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6c4.4,0,8.2,2.2,10.4,5.6
            L249,252c-1.8-2.6-4.7-4.4-8.2-4.4c-5.5,0-10,4.6-10,10.1s4.4,10.1,10,10.1C244.2,267.8,247.2,266.1,249,263.5z"
              />
              <path
                d="M266.5,267.4v2.5c-3.9,0.4-8.2,0-8.2-7.3v-14.3h-4.2v-2.6h4.2v-5h2.5v5h5.6v2.6h-5.6v14.3
            C260.8,266.1,261.9,267.8,266.5,267.4z"
              />
              <path
                d="M272.3,239c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7C273,240.7,272.3,239.9,272.3,239z
             M272.7,245.7h2.6v24h-2.6V245.7z"
              />
              <path
                d="M305.6,257.7c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
            C300.1,245.1,305.6,250.8,305.6,257.7z M303.1,257.7c0-5.5-4.4-10.1-10-10.1s-10,4.6-10,10.1s4.4,10.1,10,10.1
            S303.1,263.3,303.1,257.7z"
              />
              <path
                d="M331.2,255.5v14.2h-2.5v-14.2c0-3.3-1.6-7.9-7.1-7.9c-5.5,0-8,4.6-8,7.9v14.2h-2.5v-24h2.5v3.8c1.6-2.5,4.2-4.4,8-4.4
            C328.5,245.1,331.2,250.9,331.2,255.5z"
              />
              <path d="M371.1,239.1H362v30.6h-2.5v-30.6h-9.1v-2.6h20.7V239.1z" />
              <path
                d="M395.3,259h-20.7c0.6,4.9,4.6,8.8,9.7,8.8c3.2,0,5.8-1.2,7.7-3.5l2.2,1.4c-2.4,3-5.8,4.6-9.9,4.6
            c-6.9,0-12.3-5.7-12.3-12.6c0-6.8,5-12.6,11.9-12.6C390.6,245.1,395.8,250.7,395.3,259z M374.7,256.4h18.1
            c-0.6-5.3-4.2-8.8-8.8-8.8C378.9,247.6,375.2,251.6,374.7,256.4z"
              />
              <path
                d="M419.8,263.5l2.3,1.2c-2.2,3.4-6.1,5.7-10.4,5.7c-7,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6c4.4,0,8.2,2.2,10.4,5.6
            l-2.3,1.2c-1.8-2.6-4.7-4.4-8.2-4.4c-5.5,0-10,4.6-10,10.1s4.4,10.1,10,10.1C415.1,267.8,418.1,266.1,419.8,263.5z"
              />
              <path
                d="M447.2,255.5v14.2h-2.6v-14.2c0-3.3-1.6-7.9-7.1-7.9s-8,4.6-8,7.9v14.2h-2.5v-33.2h2.5v12.9c1.6-2.5,4.2-4.4,8-4.4
            C444.5,245.1,447.2,250.9,447.2,255.5z"
              />
              <path
                d="M474.4,255.5v14.2h-2.5v-14.2c0-3.3-1.6-7.9-7.1-7.9s-8,4.6-8,7.9v14.2h-2.5v-24h2.5v3.8c1.6-2.5,4.2-4.4,8-4.4
            C471.7,245.1,474.4,250.9,474.4,255.5z"
              />
              <path
                d="M504.8,257.7c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
            C499.3,245.1,504.8,250.8,504.8,257.7z M502.3,257.7c0-5.5-4.4-10.1-10-10.1s-10,4.6-10,10.1s4.4,10.1,10,10.1
            S502.3,263.3,502.3,257.7z"
              />
              <path d="M512.8,236.5v33.2h-2.5v-33.2H512.8z" />
              <path
                d="M543.2,257.7c0,6.9-5.5,12.6-12.5,12.6c-6.9,0-12.5-5.7-12.5-12.6c0-7,5.5-12.6,12.5-12.6
            C537.7,245.1,543.2,250.8,543.2,257.7z M540.7,257.7c0-5.5-4.4-10.1-10-10.1s-10,4.6-10,10.1s4.4,10.1,10,10.1
            S540.7,263.3,540.7,257.7z"
              />
              <path
                d="M571,245.7v21.5c0,6.1-4.1,12.1-12.2,12.1c-4.2,0-8-1.7-10.3-4.6l2.1-1.4c1.9,2.4,4.7,3.6,8.2,3.6c6.7,0,9.6-4.8,9.6-9.6
            v-3.5c-1.8,3.2-5.2,5.4-9.4,5.4c-6.7,0-12-5.3-12-12c0-6.7,5.3-11.9,12-11.9c4.2,0,7.6,2.1,9.4,5.4v-4.8H571z M568.4,257.1
            c0-5.3-4.1-9.4-9.4-9.4c-5.4,0-9.5,4.1-9.5,9.4c0,5.4,4.1,9.4,9.5,9.4C564.3,266.5,568.4,262.4,568.4,257.1z"
              />
              <path
                d="M577.7,239c0-0.9,0.7-1.7,1.7-1.7s1.7,0.8,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7S577.7,239.9,577.7,239z M578.1,245.7h2.6v24
            h-2.6V245.7z"
              />
              <path
                d="M609.4,259h-20.7c0.6,4.9,4.6,8.8,9.7,8.8c3.2,0,5.8-1.2,7.7-3.5l2.2,1.4c-2.4,3-5.8,4.6-9.9,4.6
            c-6.9,0-12.3-5.7-12.3-12.6c0-6.8,5-12.6,11.9-12.6C604.7,245.1,609.8,250.7,609.4,259z M588.7,256.4h18.1
            c-0.6-5.3-4.2-8.8-8.8-8.8C592.9,247.6,589.2,251.6,588.7,256.4z"
              />
              <path
                d="M629.9,263.1c0,3.6-2.6,7.2-8.4,7.2c-4.6,0-7.7-2.6-8.6-5.5l2.4-0.8c0.6,1.9,2.9,3.7,6.2,3.7c3.6,0,5.9-1.8,5.9-4.6
            c0-2.6-1.8-3.4-5.7-4.2c-3.6-0.7-7.9-2-7.9-6.8c0-3.9,3.2-7,7.5-7c3.7,0,6.5,2,7.5,5.4l-2.5,0.7c-0.7-2.2-2.5-3.5-5-3.5
            c-2.9,0-5,1.9-5,4.5c0,2.7,2.3,3.6,6,4.4C625.7,257.2,629.9,258.3,629.9,263.1z"
              />
            </g>
          )}
        </g>
      </svg> */}
    </div>
  );
}

export default Logo;
