import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AnimatePresence, m } from "framer-motion";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import { resetForm, sendEmail } from "../Functions/Utilities";
import MessageBox from "../Components/MessageBox/MessageBox";
import Buttons from "../Components/Button/Buttons";
import GoogleMap from "../Components/GoogleMap/GoogleMap";

// Data
import InViewPort from "../Components/InViewPort";
import { NotFoundLayout } from "../App";
import LoadingAnimation from "./LoadingAnimation";
import PageLayout from "./PageLayout";
import { Form, Formik } from "formik";
import { ContactFormSchema, ContactFormStyle03Schema } from "../Components/Form/FormSchema";
import { Checkbox, Input, TextArea } from "../Components/Form/Form";
import ServerClient from "../API/ServerClient";
import ContactForm from "../Components/ContactForm/ContactForm";

const Contact = (props) => {
  const [applicationModel, setApplicationModel] = useState({});
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [langName, setLangName] = useState(ServerClient.actions.getActiveLang());
  const form = useRef(null);

  useEffect(() => {
    ServerClient.actions.getAppModel((appModel) => {
      setApplicationModel(appModel);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (props.pageKey) {
      ServerClient.actions.getPageData(props.pageKey, (response) => {
        if (response?.success) {
          setPageDataActiveLang(response.data);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.pageKey]);

  const setPageDataActiveLang = (responseData) => {
    if (langName !== "tr" && responseData.otherLangs?.[langName]) {
      setPageData(responseData.otherLangs?.[langName]);
    } else {
      setPageData(responseData);
    }
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  let pageSections = pageData.sections || [];
  let contactFormSection = pageSections.find((s) => s.type === "contactForm");

  if (pageData) {
    return (
      <>
        <PageLayout pageKey={props.pageKey} />
        {/* <InViewPort>
          <ContactForm contactFormSection={contactFormSection} />
        </InViewPort> */}
      </>
    );
  } else {
    return <NotFoundLayout />;
  }
};

Contact.propTypes = {
  pageKey: PropTypes.any,
};

export default Contact;
